"use client";
import React, { useState } from "react";

export const NavbarComponent = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-black text-white font-popping">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="#"
          className="flex items-center mx-auto md:mx-0 space-x-3 rtl:space-x-reverse"
        >
          <img
            src="https://files.kick.com/images/user/15777046/profile_image/conversion/bfdd6d69-bf93-4fc1-b9fd-cb56c7247cf2-fullsize.webp"
            className="h-8"
            alt="Kick Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap hover:text-red-700 dark:hover:text-red-700 text-white select-none">
            Bereavednickel7
          </span>
        </a>
        <button
          onClick={toggleDropdown}
          className="md:hidden text-white hover:text-red-700 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        <div
          className={`${
            isDropdownOpen ? "block" : "hidden"
          } w-full md:flex md:w-auto md:order-1`}
          id="navbar-language"
        >
          <ul className="flex flex-col font-medium mt-4 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:bg-black"></ul>
          <ul className="flex flex-row flex-wrap justify-center items-center mb-3 text-sm font-medium text-red-600 sm:mb-0">
            <li className="mx-1.5 md:mx-3">
              <a
                href="https://kick.com/bereavednickel7"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-10 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 78 26"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 0H8.26393V5.71333H11.0186V2.85667H13.7732V0H22.0371V8.57H19.2825V11.4267H16.5279V14.2833H19.2825V17.14H22.0371V25.71H13.7732V22.8533H11.0186V19.9967H8.26393V25.71H0V0ZM55.0929 0H63.3568V5.71333H66.1114V2.85667H68.8661V0H77.13V8.57H74.3754V11.4267H71.6207V14.2833H74.3754V17.14H77.13V25.71H68.8661V22.8533H66.1114V19.9967H63.3568V25.71H55.0929V0ZM24.7918 0H33.0557V25.71H24.7918V0ZM44.0743 0H38.565V2.85667H35.8104V22.8533H38.565V25.71H44.0743H52.3382V17.14H44.0743V8.57H52.3382V0H44.0743Z"
                  />
                </svg>
                <span className="sr-only">Kick</span>
              </a>
            </li>

            <li className="mx-1.5 md:mx-3">
              <a
                href="https://www.youtube.com/@bereavednickel7"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M23.499 6.2s-.237-1.675-.963-2.411c-.92-.96-1.949-1.002-2.42-1.058C15.58 2.499 12 2.499 12 2.499h-.02s-3.58 0-8.115.232c-.47.056-1.5.098-2.419 1.058C.738 4.525.5 6.2.5 6.2s-.232 1.96-.232 3.92v1.96c0 1.96.232 3.92.232 3.92s.237 1.675.963 2.411c.92.96 2.128.93 2.66 1.056 1.936.186 8.44.237 8.44.237s3.58 0 8.115-.232c.47-.056 1.5-.098 2.42-1.058.725-.736.963-2.411.963-2.411s.232-1.96.232-3.92v-1.96c0-1.96-.232-3.92-.232-3.92zm-13.86 9.275v-7.15l6.3 3.575-6.3 3.575z"
                    clipRule="evenodd"
                    fill="currentColor"
                  />
                </svg>
                <span className="sr-only">YouTube</span>
              </a>
            </li>

            <li className="mx-1.5 md:mx-3">
              <a
                href="https://discord.gg/ZJEc8YcHTx"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 21 16"
                >
                  <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                </svg>
                <span className="sr-only">Discord</span>
              </a>
            </li>
            <li className="mx-1.5 md:mx-3">
              <a
                href="https://x.com/Bereavednickel7"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 17"
                >
                  <path
                    fillRule="evenodd"
                    d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                    clipRule="evenodd"
                    fill="currentColor"
                  />
                </svg>
                <span className="sr-only">Twitter</span>
              </a>
            </li>

            <li className="mx-1.5 md:mx-3">
              <a
                href="https://www.tiktok.com/@b.nickel7"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
                </svg>
                <span className="sr-only">TikTok</span>
              </a>
            </li>
            {/* <li className="mx-1.5 md:mx-3">
              <a
                href="https://www.twitch.tv/BereavedNickel7"
                target="_blank"
                className="text-white hover:text-red-700 dark:hover:text-red-700"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 28"
                  fill="currentColor"
                >
                  <path d="M2.007 0L.083 4.536v20.33h6.358V28h3.82l3.81-3.81h5.474L24 18.434V0H2.007zM21.29 16.708l-3.247 3.244H13.51l-3.81 3.816v-3.816H5.72V2.716h15.573v13.992zm-4.268-8.148v6.34h-2.12v-6.34h2.12zm-6.274 0v6.34H8.628v-6.34h2.12z" />
                </svg>
                <span className="sr-only">Twitch</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};
